import React, { CSSProperties } from 'react'

import { ColumnData, RowData } from '../virtualized-table/virtualized-table'
import { CellConfigInterface, DragObjectItemInterface } from '../virtualized-table/typedefs'
import VTDraggableCell from '../draggable-cell/draggable-cell'

interface TableCellPreviewProps<T extends RowData> {
  cellConfig?: CellConfigInterface
  columns: ColumnData<T>[]
  headerCells: HTMLCollection
  item: DragObjectItemInterface<T>
  style?: CSSProperties
}

const TableCellPreview = <T extends RowData>({
  cellConfig,
  columns,
  headerCells,
  item,
  style
}: TableCellPreviewProps<T>) => {
  const getValue = () => ({
    node: columns[item.startIndex]?.getValue(item.item).node
  })
  const cellComputedStyles =
    headerCells && headerCells?.item(item.startIndex)
      ? getComputedStyle(headerCells?.item(item.startIndex))
      : undefined
  return cellConfig?.draggableCell ? (
    <div
      style={{
        backgroundColor: style?.backgroundColor,
        boxShadow: '0 0 3px 1px #c8c8c8',
        width: cellComputedStyles?.width
      }}
    >
      <VTDraggableCell
        cellConfig={cellConfig}
        headerCell={headerCells?.item(item.startIndex)}
        getValue={getValue}
        cellIndex={item.startIndex}
        rowItem={item.item}
      />
    </div>
  ) : null
}

export default TableCellPreview
