import React, { useEffect, useRef, useState } from 'react'
import { VariableSizeList as List } from 'react-window'

import Paper from '@material-ui/core/Paper'
import Table from '@material-ui/core/Table'
import TableContainer from '@material-ui/core/TableContainer'
import TableHead from '@material-ui/core/TableHead'
import TableRow from '@material-ui/core/TableRow'
import TableBody from '@material-ui/core/TableBody'

import TableHeadCell from '../table-head-cell/table-head-cell'
import {
  CellConfigInterface,
  DragObjectItemInterface,
  DropTypeEnum,
  RowConfigInterface,
  TableConfigInterface
} from './typedefs'
import CustomDragPreviewFactory from '../custom-drag-preview/custom-drag-preview'
import MemoizedRow from '../memoized-row/memoized-row'
import * as styles from './virtualized-table.module.less'
import { Spinner } from '@clubspark-react/clubspark-react-tools'
import { Grid } from '@material-ui/core'
import { useTranslation } from 'react-i18next'

export const DEFAULT_ROW_SIZE = 52

export interface RowData {
  id: string
  className?: string
}

export interface ColumnData<T extends RowData> {
  key: string
  getTitle?: () => { node: React.ReactNode; className?: string }
  getValue: (
    t: T,
    cellIndex?: number
  ) => { node: React.ReactNode; className?: string; isDragHandle?: boolean }
  hidden?: (t?: T) => boolean
}

interface MuiVirtualizedTableProps<T extends RowData> {
  id?: string
  cellConfig?: CellConfigInterface
  columns: ColumnData<T>[]
  customTableRowPreview?: (item: DragObjectItemInterface<T>) => React.ReactNode
  customRowCellPreview?: (item: DragObjectItemInterface<T>) => React.ReactNode
  data?: T[]
  initialScrollOffset?: number
  onCellClick?: (
    event?: React.MouseEvent<HTMLDivElement, MouseEvent>,
    rowItem?: T,
    cellIIndex?: number
  ) => void
  onDrop?: (
    dragObject: DragObjectItemInterface<T>,
    endIndex: number,
    dropType: DropTypeEnum,
    dropRowId?: string
  ) => unknown
  onRowClick?: (
    event?: React.MouseEvent<HTMLDivElement, MouseEvent>,
    rowItem?: T,
    rowIndex?: number
  ) => void
  onRowMouseOver?: (
    event?: React.MouseEvent<HTMLDivElement, MouseEvent>,
    rowItem?: T,
    rowIndex?: number
  ) => void
  onRowMouseOut?: (
    event?: React.MouseEvent<HTMLDivElement, MouseEvent>,
    rowItem?: T,
    rowIndex?: number
  ) => void
  rowConfig?: RowConfigInterface
  tableConfig?: TableConfigInterface
  loading?: boolean
  spinner?: ReactNode
}

const VirtualizedTable = <T extends RowData>({
  id,
  cellConfig,
  columns,
  customRowCellPreview,
  customTableRowPreview,
  data,
  initialScrollOffset,
  onCellClick,
  onDrop,
  onRowClick,
  onRowMouseOver,
  onRowMouseOut,
  rowConfig,
  tableConfig,
  loading = false,
  spinner
}: MuiVirtualizedTableProps<T>) => {
  const { t } = useTranslation()
  const headerRowRef = useRef<HTMLDivElement>(null)
  const virtualListRef = useRef<HTMLDivElement>(null)
  const paperRef = useRef<HTMLDivElement>(null)
  const [VLRef, setVLRef] = useState(null)
  const [headerCells, setHeaderCells] = useState<HTMLCollection>(null)
  const [paperDiv, setPaperDiv] = useState(null)
  const [domColumnsCount, triggerUpdateColumns] = useState(null)
  const [domRowsCount, triggerUpdateRows] = useState(null)
  const paperComputedStyle = paperDiv ? getComputedStyle(paperDiv) : undefined
  const previewBackgroundColor = paperComputedStyle?.backgroundColor || '#ffffff'
  const previewWidth = paperComputedStyle?.width || '100%'

  // marginLeft fixes custom preview drag handle issue
  const previewStyle = {
    backgroundColor: previewBackgroundColor,
    width: previewWidth
  }

  useEffect(() => {
    setVLRef(virtualListRef?.current)
    setHeaderCells(headerRowRef?.current?.children)
    setPaperDiv(paperRef?.current)

    // provides support for initialScrollOffset until the issue within the react-window is resolved
    if (initialScrollOffset && virtualListRef?.current) {
      virtualListRef.current.scrollTo({ top: initialScrollOffset })
    }

    /**
     * This is important because in cases where the user dynamically adds / removes columns, the component needs
     * to be re-rendered again, so the refs can be consistent. Without this, DOM refs are always "late by one" render.
     */
    if (domColumnsCount !== columns.length) {
      triggerUpdateColumns(columns.length)
    }
    if (domRowsCount !== data.length) {
      triggerUpdateRows(data.length)
    }
  }, [domColumnsCount, domRowsCount, columns.length, data.length, initialScrollOffset])

  const getRowHeight = (index: number) => {
    if (rowConfig?.rowSize) {
      return rowConfig.rowSize(index)
    }
    return DEFAULT_ROW_SIZE
  }
  const allRowsHeight = data.reduce((total, _, index) => total + getRowHeight(index), 0)
  const rowData = {
    data: data,
    props: {
      cellConfig,
      columns,
      headerCells,
      onCellClick,
      onDrop,
      onRowClick,
      onRowMouseOver,
      onRowMouseOut,
      rowConfig,
      VLRef
    }
  }

  return (
    <>
      <TableContainer
        ref={paperRef}
        component={Paper}
        className={tableConfig?.className ? tableConfig.className : ''}
      >
        <Table
          component="div"
          id={id}
          style={{
            backgroundColor: previewBackgroundColor
          }}
        >
          <TableHead component="div" style={{ display: 'table', width: `100%` }}>
            <TableRow ref={headerRowRef} component="div">
              {columns.map(
                column =>
                  !column?.hidden?.() && (
                    <TableHeadCell key={column.key} cellConfig={cellConfig} column={column} />
                  )
              )}
            </TableRow>
          </TableHead>
          {domColumnsCount && domColumnsCount > 0 && domColumnsCount !== columns.length ? (
            <div style={{ height: tableConfig?.tableHeight || 300, width: '100%' }} />
          ) : (
            <>
              {loading && (
                <Grid container justify="center" className={styles.spinnerContainer}>
                  {spinner ? spinner : <Spinner />}
                </Grid>
              )}
              {!loading && data?.length === 0 && (
                <Grid
                  container
                  justify="center"
                  alignItems="center"
                  style={{ height: tableConfig?.tableHeight || 'auto' }}
                >
                  <p>{t('no results')}</p>
                </Grid>
              )}
              {!loading && Boolean(data?.length) && (
                <TableBody component="div" style={{ display: 'table', width: `100%` }}>
                  <List
                    height={tableConfig?.tableHeight || allRowsHeight}
                    style={{overflow: 'hidden'}} // Remove scroll bar
                    itemCount={data?.length || 0}
                    itemData={rowData}
                    itemSize={getRowHeight}
                    //* TODO: reintroduce this once react-window fixes it
                    // initialScrollOffset={initialScrollOffset}
                    overscanCount={4}
                    outerRef={virtualListRef}
                    width="100%"
                  >
                    {MemoizedRow}
                  </List>
                </TableBody>
              )}
            </>
          )}
        </Table>
      </TableContainer>
      <CustomDragPreviewFactory
        cellConfig={cellConfig}
        columns={columns}
        customTableRowPreview={customTableRowPreview}
        customRowCellPreview={customRowCellPreview}
        headerCells={headerCells}
        previewStyle={previewStyle}
        rowConfig={rowConfig}
        virtualListDiv={VLRef}
      />
    </>
  )
}

export default VirtualizedTable

// {loading ? (
//   <Grid container justify="center" className={styles.spinnerContainer}>
//     <Spinner />
//   </Grid>
// ) : (
//   <List
//     height={tableConfig?.tableHeight || allRowsHeight}
//     itemCount={data?.length || 0}
//     itemData={rowData}
//     itemSize={getRowHeight}
//     //* TODO: reintroduce this once react-window fixes it
//     // initialScrollOffset={initialScrollOffset}
//     overscanCount={4}
//     outerRef={virtualListRef}
//     width="100%"
//   >
//     {MemoizedRow}
//   </List>
// )}
