import { getToken } from './auth'

interface RequestBody {
  [key: string]: any
}

interface FetchResponse<T> {
  data?: T
  error?: string
}

async function fetchData<T>(
  url: string,
  method: string,
  orgId: string,
  body?: RequestBody
): Promise<FetchResponse<T>> {
  try {
    const token = getToken() || ''
    const response = await fetch(url, {
      method: method.toUpperCase(),
      headers: {
        'Content-Type': 'application/json',
        authorization: token,
        'x-clubspark-provider-id': orgId,
      },
      body: body ? JSON.stringify(body) : undefined
    })

    const responseData = await response.json()

    if (response.ok) {
      return { data: responseData }
    } else {
      return { error: responseData.error || 'An unknown error occurred' }
    }
  } catch (e) {
    let message = 'Unknown Error'
    if ((e as Error).message) message = (e as Error).message
    return { error: message }
  }
}

export default fetchData
