import React, { useState, useEffect, useCallback, useMemo } from 'react'
import Spinner from '../../spinner/spinner'
import TableContainer from '@material-ui/core/TableContainer'
import Table from '@material-ui/core/Table'
import TableHead from '@material-ui/core/TableHead'
import TableRow from '@material-ui/core/TableRow'
import TableCell from '@material-ui/core/TableCell/TableCell'
import TableBody from '@material-ui/core/TableBody'
import TableFooter from '@material-ui/core/TableFooter'
import TablePagination from '@material-ui/core/TablePagination'
import {
  SearchPeople,
  SearchPeople_searchPeople_results
} from '../../../graphql-types/SearchPeople'
import { useTranslation } from 'react-i18next'
import { Checkbox } from '@material-ui/core'

type TableProps = {
  players: SearchPeople,
  loading: boolean,
  page: number,
  perPage: number
  onChangeRows: (event) => void,
  setSelectedPlayer: (player) => void,
  onChangePage: (event, newPage) => void,
}

const RankingSelectPlayerTable = ({ players, loading, page, perPage, onChangeRows, onChangePage, setSelectedPlayer}: TableProps) => {
  const { t } = useTranslation();
  const [selected, setSelected] = React.useState<SearchPeople_searchPeople_results>();

  const handleClick = (event, row) => {
    setSelected(row)
    setSelectedPlayer(row)
  }

  useEffect(() => {
    //  Reset modal data on close
    setSelected(undefined);
  }, [players]);

  return (
    <>
      {loading && <Spinner />}
      {!loading && players?.searchPeople?.results?.length === 0 &&
        <div style={{textAlign: 'center'}}>{t('no players found')}</div>
      }
      {!loading && players?.searchPeople?.results?.length > 0 &&
      <TableContainer>
          <Table aria-label="simple table">
            <TableHead>
              <TableRow>
                <TableCell/>
                <TableCell><strong>{t('name')}</strong></TableCell>
                <TableCell><strong>{t('school')}</strong></TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {players.searchPeople.results.map((row, index) => {
                const isItemSelected = row.personId === selected?.personId;
                const schoolName = row.groups.find(grp => grp.year)

                return (
                  <TableRow key={index} onClick={(event) => handleClick(event, row)} hover>
                    <TableCell padding="checkbox">
                      <Checkbox
                        color="primary"
                        checked={isItemSelected}
                      />
                    </TableCell>
                    <TableCell component="th" scope="row">
                      {row.standardGivenName} {row.standardFamilyName}
                    </TableCell>
                    <TableCell>{schoolName?.groupName}</TableCell>
                  </TableRow>
                )
              })}
            </TableBody>
            <TableFooter>
              <TableRow>
                <TablePagination
                  rowsPerPageOptions={[5, 10]}
                  count={players.searchPeople.total}
                  page={page}
                  rowsPerPage={perPage}
                  onChangeRowsPerPage={onChangeRows}
                  onChangePage={onChangePage}
                />
              </TableRow>
            </TableFooter>
          </Table>
        </TableContainer>
      }
    </>
  )
}

export default RankingSelectPlayerTable
