import React from 'react'
import DragIndicatorIcon from '@material-ui/icons/DragIndicator'
import SvgIcon from '@material-ui/core/SvgIcon'
import * as styles from './drag-handle.module.less'
interface UMTableDragHandleCellProps {
  courtId?: boolean
}
const DragHandle: React.FC<UMTableDragHandleCellProps> = () => {
  return (
    <div className={styles.dragHandleCellWrapper}>
      <SvgIcon className={styles.dragHandleCell} component={DragIndicatorIcon} />
    </div>
  )
}
export default DragHandle
