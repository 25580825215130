import React from 'react'
import Spinner from '../spinner/spinner'
import Button from '../button/button'
import { useTranslation } from 'react-i18next'
import useModeReport from 'src/hooks/use-mode-report/use-mode-report'
import { Typography } from '@material-ui/core'

interface ModeReportProps {
  reportName?: string
  filters?: {
    [key: string]: any
  }
  showDownloadButton?: boolean
  setModeDownloadUrl?: (url: string) => void
}

const ModeReport: React.FC<ModeReportProps> = ({
  reportName,
  filters,
  showDownloadButton,
  setModeDownloadUrl
}) => {
  const { t } = useTranslation()
  const modeReport = useModeReport({ reportName, filters })

  if ('error' in modeReport) {
    return <Typography>{t('error fetching report')}</Typography>
  }

  const { url, isLoading, csvExportLink } = modeReport
  setModeDownloadUrl ? setModeDownloadUrl(csvExportLink) : null

  return !isLoading ? (
    <>
      <div>
        <iframe title="Mode report embed" src={url} width="100%" height="750" frameBorder="0" />
      </div>
      {showDownloadButton && (
        <Button
          spacing={{ margins: { auto: 'left' } }}
          onClick={() => (window.location.href = csvExportLink)}
        >
          {t('download report')}
        </Button>
      )}
    </>
  ) : (
    <Spinner />
  )
}

ModeReport.defaultProps = {
  showDownloadButton: true,
}

export default React.memo(ModeReport)
