import graphql from 'graphql-tag'

export const GET_ORGANISATION_BY_TEAM_ID = graphql`
  query organisationByTeamId($teamId: ID!) {
    organisationByTeamId(teamId: $teamId) {
      id
      name
      schools {
        name
        rankingAwardRegion
        itaRegion
        conference
      }
      divisionName
      conferenceName
    }
  }
`
