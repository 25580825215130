import React, { memo } from 'react'
import { areEqual, ListChildComponentProps } from 'react-window'
import VTDraggableRow from '../draggable-row/draggable-row'

const MemoizedRow = memo(({ index, style, data }: ListChildComponentProps) => {
  const rowItem = data.data[index]
  const customClassName = rowItem.className
  const {
    cellConfig,
    columns,
    headerCells,
    onCellClick,
    onDrop,
    onRowClick,
    onRowMouseOver,
    onRowMouseOut,
    rowConfig,
    VLRef
  } = data.props
  return (
    <VTDraggableRow
      cellConfig={cellConfig}
      columns={columns}
      customClassName={customClassName}
      headerCells={headerCells}
      index={index}
      onCellClick={onCellClick}
      onDrop={onDrop}
      onRowMouseOver={onRowMouseOver}
      onRowMouseOut={onRowMouseOut}
      onRowClick={onRowClick}
      rowItem={rowItem}
      rowConfig={rowConfig}
      style={style}
      virtualListDiv={VLRef}
    />
  )
}, areEqual)

export default MemoizedRow
