import React from 'react'
import SEO from 'src/components/seo'
import Layout from 'src/components/layout/layout'
import { usePrivateRoute } from 'src/utils/auth'
import RankingEntry from 'src/components/ranking-entry/ranking-entry'
import { RouteComponentProps, Router } from '@reach/router'
import USTARankingsRun from 'src/components/rankings-run/rankings-run'
import RankingRun from 'src/components/rankings/ranking-run/ranking-run'
import { useV2Rankings } from 'src/utils/useV2Rankings'

const RankingPage = () => {
  usePrivateRoute()

  return (
    <Layout>
      <SEO title="Rankings" />
      <Router basepath="/rankings">
        <RankingRoute path="/:rankingId" />
      </Router>
    </Layout>
  )
}

interface RankingRouteProps extends RouteComponentProps {
  rankingId?: string
}

const RankingRoute: React.FC<RankingRouteProps> = ({ rankingId }) => {
  if (process.env.GATSBY_CLIENT === 'ITA') {
    return <RankingEntry rankingId={rankingId} />
  } else {
    return useV2Rankings() ? <RankingRun rankingId={rankingId} /> : <USTARankingsRun rankingId={rankingId} />
  }
}

export default RankingPage
