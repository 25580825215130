import { useCallback, useEffect, useState } from 'react'
import { useOrgId } from 'src/apollo/local-state'
import { getEnvConfig } from 'src/config/config'
import fetchData from 'src/utils/fetch-api.ts'

interface useModeReportProps {
  reportName?: string
  filters?: {
    [key: string]: any
  }
}

type UseModeReportOutput = UseModeReportOutputSuccess | UseModeReportOutputError

interface UseModeReportOutputSuccess {
  url: string
  csvExportLink: string
  isLoading: boolean
}

interface UseModeReportOutputError {
  error: string
}

/**
 * useModeReport
 * Hook to fetch the URL for a hook report, given an ID and filters
 * @param reportName - the ID of the report to show
 * @param filters - the filters to apply to the report
 * @returns { url, csvExportLink, isLoading }
 */
const useModeReport = ({ reportName, filters }: useModeReportProps): UseModeReportOutput => {
  if (!reportName) {
    return { error: 'No report name provided' }
  }
  const [url, setUrl] = useState('')
  const [csvExportLink, setCsvExportLink] = useState('')
  const [isLoading, setIsLoading] = useState(false)
  const [error, setError] = useState('')
  const orgId = useOrgId()

  const handlePostData = useCallback(async () => {
    try {
      setIsLoading(true)

      const { data, error } = await fetchData<{ data: string }>(
        `${getEnvConfig().KUBE_BASE_URL}/report-signature-api/report-signature`,
        'POST',
        orgId,
        { reportName, filters }
      )

      if (error) {
        setError(error)
      }

      setUrl(data?.data || '')
    } catch (e) {
      let message = 'Unknown Error'
      if ((e as Error).message) message = (e as Error).message
      setError(message)
    } finally {
      setIsLoading(false)
    }
  }, [orgId, reportName, filters])

  useEffect(() => {
    handlePostData()
    window.addEventListener('message', function(e) {
      if (e.origin === 'https://app.mode.com' && e.data['type'] === 'reportExportPaths') {
        const modeBaseUrl = e.origin
        // CSV Export
        const csvExportUrl = e.data['report_csv_export_path']
        setCsvExportLink(modeBaseUrl + csvExportUrl)
      }
    })
  }, [reportName, filters, handlePostData])

  if (error) {
    return { error }
  }

  return { url, csvExportLink, isLoading }
}

export default useModeReport
