import React, { CSSProperties } from 'react'
import { ColumnData, RowData } from '../virtualized-table/virtualized-table'
import VTDraggableRow from '../draggable-row/draggable-row'
import { RowConfigInterface } from '../virtualized-table/typedefs'

interface TableRowPreviewProps<T extends RowData> {
  columns: ColumnData<T>[]
  headerCells: HTMLCollection
  itemWrapper: any
  rowConfig?: RowConfigInterface
  style?: CSSProperties
  virtualListDiv: HTMLDivElement
}

const TableRowPreview = <T extends RowData>({
  columns,
  headerCells,
  itemWrapper,
  rowConfig,
  style,
  virtualListDiv
}: TableRowPreviewProps<T>) => {
  const item = itemWrapper.item

  return rowConfig?.draggableRow ? (
    <div style={{ boxShadow: '0 0 3px 1px #c8c8c8', ...style }}>
      <VTDraggableRow
        columns={columns}
        customClassName={item.className}
        headerCells={headerCells}
        index={itemWrapper.startIndex}
        rowItem={item}
        virtualListDiv={virtualListDiv}
      />
    </div>
  ) : null
}

export default TableRowPreview
