import React, { CSSProperties } from 'react'
import { useDragLayer, XYCoord } from 'react-dnd'

import TableRowPreview from '../table-row-preview/table-row-preview'
import CustomPreviewWrapper from '../custom-preview-wrapper/custom-preview-wrapper'
import { ColumnData, RowData } from '../virtualized-table/virtualized-table'
import TableCellPreview from '../table-cell-preview/table-cell-preview'
import {
  CellConfigInterface,
  DragObjectItemInterface,
  RowConfigInterface
} from '../virtualized-table/typedefs'

const layerStyles: React.CSSProperties = {
  position: 'fixed',
  pointerEvents: 'none',
  zIndex: 100,
  left: 0,
  top: 0,
  width: '100%',
  height: '100%'
}
const getItemStyles = (initialOffset: XYCoord | null, currentOffset: XYCoord | null) => {
  if (!initialOffset || !currentOffset) {
    return {
      display: 'none'
    }
  }
  const { x, y } = currentOffset
  const transform = `translate(${x}px, ${y}px)`
  return {
    transform,
    WebkitTransform: transform
  }
}

interface CustomDragPreviewFactoryProps<T extends RowData> {
  cellConfig?: CellConfigInterface
  customTableRowPreview?: (item: DragObjectItemInterface<T>) => React.ReactNode
  customRowCellPreview?: (item: DragObjectItemInterface<T>) => React.ReactNode
  columns: ColumnData<T>[]
  headerCells: HTMLCollection
  previewStyle?: CSSProperties
  rowConfig?: RowConfigInterface
  virtualListDiv: HTMLDivElement
}

const CustomDragPreviewFactory = <T extends RowData>({
  cellConfig,
  customTableRowPreview,
  customRowCellPreview,
  columns,
  headerCells,
  previewStyle,
  rowConfig,
  virtualListDiv
}: CustomDragPreviewFactoryProps<T>) => {
  const { itemType, isDragging, item, initialOffset, currentOffset } = useDragLayer(monitor => ({
    item: monitor.getItem(),
    itemType: monitor.getItemType(),
    initialOffset: monitor.getInitialSourceClientOffset(),
    currentOffset: monitor.getSourceClientOffset(),
    isDragging: monitor.isDragging()
  }))

  const renderItem = () => {
    switch (itemType) {
      case 'draggableRow':
        return customTableRowPreview ? (
          <CustomPreviewWrapper>{customTableRowPreview(item)}</CustomPreviewWrapper>
        ) : (
          <TableRowPreview
            columns={columns}
            headerCells={headerCells}
            itemWrapper={item}
            style={previewStyle}
            rowConfig={rowConfig}
            virtualListDiv={virtualListDiv}
          />
        )
      case 'draggableCell':
        return customRowCellPreview ? (
          <CustomPreviewWrapper>{customRowCellPreview(item)}</CustomPreviewWrapper>
        ) : (
          <TableCellPreview
            cellConfig={cellConfig}
            columns={columns}
            headerCells={headerCells}
            item={item}
            style={previewStyle}
          />
        )
      default:
        return null
    }
  }
  if (!isDragging) {
    return null
  }
  return (
    <div style={layerStyles}>
      <div style={getItemStyles(initialOffset, currentOffset)}>{renderItem()}</div>
    </div>
  )
}

export default CustomDragPreviewFactory
