import graphql from 'graphql-tag'

export const GET_RANK_LIST_DOWNLOAD_TEMPLATE = graphql`
  query RanklistDownload($id: String!, $skip: Int!, $limit: Int!) {
    ranklist(id: $id) {
      id
      listType
      visible
      publishDate
      updatedAt
      createdAt
      rankingItemsPaginated(itemPageArgs: { skip: $skip, limit: $limit }) {
        items {
          id
          participants {
            itemId
            name
            city
            state
            section
            district
            birthDate
          }
          sectionRank
          districtRank
          rank
          points {
            singles
            doubles
            bonus
            total
          }
        }
        totalItems
      }
    }
  }
`

export const ARCHIVE_RANK_LISTS = graphql`
  mutation SoftArchiveRankLists($ids: [String!]!) {
    softArchiveRankLists(ids: $ids)
  }
`
